import type { FootprintCategory } from '../types/platform';

export const DATA_COLOR_SCALE_SCHEME = [
  '#E1AA9A',
  '#648975',
  '#6E4245',
  '#D5A357',
  '#3A406F',
  '#BE5254',
  '#BA8A69',
  '#C37345',
  '#5A6046',
  '#6C8A9E',
];

export const UNKNOWN_COLOR = '#b5b6bb';

// This utility function is used across the platform to get consistent data visualization colors
// for any kind of value. It automatically assigns colors to meaningful values like LCA stages,
// seasons, unknown placeholders, and fallbacks to a consistent hash-based color scheme otherwise.
export function pickDatavizColor(
  val: string,
): (typeof DATA_COLOR_SCALE_SCHEME)[number] {
  // Automatically use grey if value is unknown or null-like
  if (
    typeof val !== 'string' ||
    ['', 'unknown', 'null', 'n/a'].includes(val.toLowerCase())
  ) {
    return UNKNOWN_COLOR;
  }

  // Automatically use LCA stage colors
  if (
    [
      'rawMaterials',
      'RAW_MATERIALS',
      'processing',
      'COMPONENT_PROCESSING',
      'assembling',
      'ASSEMBLY',
      'distribution',
      'DISTRIBUTION',
      'use',
      'USE',
      'endOfLife',
      'END_OF_LIFE',
    ].includes(val)
  ) {
    return getLcaStageColor(val as FootprintCategory | KayaFootprintCategory);
  }

  // Automatically use season colors
  if (['SPRING', 'SUMMER', 'FALL', 'WINTER'].includes(val.toUpperCase())) {
    return getSeasonColor(val.toUpperCase() as Season);
  }

  // Automatically use transport mode colors
  if (['CAR', 'ROAD', 'SEA', 'RAIL', 'AIR'].includes(val.toUpperCase())) {
    return getTransportModeColor(val.toUpperCase() as TransportMode);
  }

  // Implementation stolen from https://github.com/Medium/oid
  let hash = 0;
  const length = val.length;

  for (let i = 0; i < length; i++) {
    hash = (hash * 31 + val.charCodeAt(i)) & 0x7fffffff;
  }

  return DATA_COLOR_SCALE_SCHEME[hash % DATA_COLOR_SCALE_SCHEME.length];
}

export type KayaFootprintCategory =
  | 'RAW_MATERIALS'
  | 'ASSEMBLY'
  | 'USE'
  | 'END_OF_LIFE'
  | 'DISTRIBUTION'
  | 'COMPONENT_PROCESSING'
  | 'TEXTILE_FORMATION'
  | 'COLORATION'
  | 'PREPARATION'
  | 'PROCESSING'
  | 'FINISHING';

export default function getLcaStageColor(
  type: FootprintCategory | KayaFootprintCategory,
) {
  switch (type) {
    // We manually map instead of using the pickDatavizColor() hash function to ensure
    // each LCA stage has a different color.
    case 'rawMaterials':
    case 'RAW_MATERIALS':
      return DATA_COLOR_SCALE_SCHEME[0];
    case 'processing':
    case 'TEXTILE_FORMATION':
    case 'COLORATION':
    case 'PREPARATION':
    case 'PROCESSING':
    case 'FINISHING':
    case 'COMPONENT_PROCESSING':
      return DATA_COLOR_SCALE_SCHEME[1];
    case 'assembling':
    case 'ASSEMBLY':
      return DATA_COLOR_SCALE_SCHEME[2];
    case 'distribution':
    case 'DISTRIBUTION':
      return DATA_COLOR_SCALE_SCHEME[3];
    case 'use':
    case 'USE':
      return DATA_COLOR_SCALE_SCHEME[4];
    case 'endOfLife':
    case 'END_OF_LIFE':
      return DATA_COLOR_SCALE_SCHEME[5];
  }
}

export type Season = 'SPRING' | 'SUMMER' | 'FALL' | 'WINTER';

export function getSeasonColor(season: Season) {
  switch (season) {
    case 'SPRING':
      return DATA_COLOR_SCALE_SCHEME[1];
    case 'SUMMER':
      return DATA_COLOR_SCALE_SCHEME[3];
    case 'FALL':
      return DATA_COLOR_SCALE_SCHEME[6];
    case 'WINTER':
      return DATA_COLOR_SCALE_SCHEME[9];
  }
}

export type TransportMode = 'CAR' | 'ROAD' | 'SEA' | 'RAIL' | 'AIR';

export function getTransportModeColor(mode: TransportMode) {
  switch (mode) {
    case 'CAR':
    case 'ROAD':
      return DATA_COLOR_SCALE_SCHEME[5];
    case 'SEA':
      return DATA_COLOR_SCALE_SCHEME[4];
    case 'RAIL':
      return DATA_COLOR_SCALE_SCHEME[3];
    case 'AIR':
      return DATA_COLOR_SCALE_SCHEME[9];
  }
}
