import { Climatiq } from '@carbonfact/shared/src/climatiq';
import type {
  MetricsCubeQueryData,
  MetricsCubeQueryOptions,
} from '@carbonfact/shared/src/types/platform/metrics-cube';
import { capitalizeWords } from '@carbonfact/shared/src/utils';
import { formatCount, formatPercent } from 'app/lib/formatNumber';
import { upperFirst } from 'lodash';
import { useTranslations } from 'next-intl';
import { type ReactNode, useCallback, useMemo } from 'react';

export interface MetricsCubeFormattingUtils {
  getMetricValue: (d: MetricsCubeQueryData) => number;
  formatMetricValue: (v: number) => string;
  metricSuffix: string;
  formatMetricsCubeString: (
    kayaString?: string | null,
    inDimension?: string | null,
  ) => string;
  getMetricOrDimensionDescription: (
    kayaString?: string | null,
  ) => ReactNode | undefined;
}

// A collection of printing and formatting utilities to display metrics-cube data
export default function useMetricsCubeFormattingUtils(
  options: MetricsCubeQueryOptions,
): MetricsCubeFormattingUtils {
  const t = useTranslations();

  const findTranslationOrNull = useCallback(
    (key: string): string | null => {
      if (t.has(key)) {
        return t(key);
      }
      return null;
    },
    [t],
  );

  const [getMetricValue, formatMetricValue, metricSuffix] = useMemo<
    [
      MetricsCubeFormattingUtils['getMetricValue'],
      MetricsCubeFormattingUtils['formatMetricValue'],
      MetricsCubeFormattingUtils['metricSuffix'],
    ]
  >(() => {
    switch (options.metric) {
      case 'FOOTPRINT':
        return [
          (d) => d.metricValue / 1000, // convert kgCO2e to tCO2e
          (v) => formatCount(v),
          'tCO2e',
        ];
      case 'UNITS':
      case 'UNITS_MEASURED':
        return [(d) => d.metricValue, (v) => formatCount(v), 'units'];
      case 'MASS':
      case 'MASS_WITH_LOSS':
      case 'MASS_MEASURED':
      case 'MASS_WITH_LOSS_MEASURED':
        return [
          (d) => d.metricValue / 1000, // convert grams to kg
          (v) => formatCount(v),
          'kg',
        ];
      case 'UNCERTAINTY':
        return [
          (d) => d.metricValue / 1000, // convert kgCO2e to tCO2e
          (v) => formatCount(v),
          'tCO2e',
        ];
      case 'composite_MASS_PER_UNIT':
        return [(d) => d.metricValue, (v) => formatCount(v), 'g/unit'];
      case 'composite_FOOTPRINT_PER_UNIT':
        return [(d) => d.metricValue, (v) => formatCount(v), 'kgCO2e/unit'];
      case 'composite_FOOTPRINT_PER_MASS':
        return [
          (d) => d.metricValue * 1000, // kgco2eq/grams to kgco2eq/kg
          (v) => formatCount(v),
          'kgCO2e/kg',
        ];
      case 'composite_UNITS_SHARE':
      case 'composite_UNITS_MEASURED_SHARE':
      case 'composite_FOOTPRINT_SHARE':
      case 'composite_MASS_SHARE':
      case 'composite_MASS_MEASURED_SHARE':
      case 'composite_MASS_WITH_LOSS_SHARE':
      case 'composite_MASS_WITH_LOSS_MEASURED_SHARE':
        return [(d) => d.metricValue, (v) => formatPercent(v), '%'];
      default:
        return [(d) => d.metricValue, (v) => formatCount(v), 'metric'];
    }
  }, [options.metric]);

  const formatMetricsCubeString = useCallback<
    MetricsCubeFormattingUtils['formatMetricsCubeString']
  >(
    (kayaString, inDimension) => {
      if (!kayaString) return 'unknown';

      const normalizedKayaString = kayaString
        .toLowerCase()
        .trim()
        .replaceAll('composite_', ''); // marker for composite metrics

      const normalizedDimension = inDimension
        ? inDimension.toLowerCase().trim().replaceAll(' ', '_')
        : null;

      // Fallback through different possibilities to convert the string to a meaningful
      // and localized UI copy

      // Metrics cube dimension name and descriptions
      const explorerDimensionName = findTranslationOrNull(
        `explorerDimensions.${normalizedKayaString}.name`,
      );
      if (explorerDimensionName) return upperFirst(explorerDimensionName);

      if (inDimension) {
        // Dimension values

        // Product materials
        if (inDimension.includes('material')) {
          const productMaterialName = findTranslationOrNull(
            `materials.${normalizedKayaString.toUpperCase()}`,
          );
          if (productMaterialName) return upperFirst(productMaterialName);
        }

        // Country codes
        if (inDimension.toLowerCase().includes('country')) {
          const countryName = findTranslationOrNull(
            `countries.${normalizedKayaString.toUpperCase()}`,
          );
          if (countryName) return upperFirst(countryName);
        }

        // Climatiq activity types, used for Expenses
        if (inDimension.includes('expense')) {
          const climatiqActivityKey = Object.entries(Climatiq.ActivityId).find(
            ([_, value]) => value === normalizedKayaString,
          )?.[0];
          if (climatiqActivityKey) {
            const climatiqActivityName = findTranslationOrNull(
              `Expenses.expense.activityTypeValues.${climatiqActivityKey}`,
            );
            if (climatiqActivityName) return upperFirst(climatiqActivityName);
          }
        }

        // Transport modes (road, air...)
        if (
          inDimension.includes('transport') ||
          inDimension.includes('travel')
        ) {
          const transportModeName = findTranslationOrNull(
            `Transport.transport.transportModeValues.${normalizedKayaString}`,
          );
          if (transportModeName) return upperFirst(transportModeName);
        }

        // GHG Protocol Scopes
        if (inDimension.includes('ghg_protocol')) {
          const ghgProtocolScopeName = findTranslationOrNull(
            `ghgProtocolChapterTitles.${normalizedKayaString.replaceAll('.', '_')}`,
          );
          if (ghgProtocolScopeName) return upperFirst(ghgProtocolScopeName);
        }
      }

      // If no translation was found, fall back to a cleaned up version of the Kaya string
      return capitalizeWords(
        normalizedKayaString
          .replaceAll('_', ' ')
          .replaceAll('#', ' ')
          .replaceAll('slug', ''),
      );
    },
    [findTranslationOrNull],
  );

  const getMetricOrDimensionDescription = useCallback<
    MetricsCubeFormattingUtils['getMetricOrDimensionDescription']
  >(
    (kayaString) => {
      if (!kayaString) return undefined;

      const normalizedKayaString = kayaString
        .toLowerCase()
        // composite entries should be treated as regular entries
        .replace('composite_', '')
        .trim();

      if (!t.has(`explorerDimensions.${normalizedKayaString}.description`)) {
        return '';
      }

      const examples = getExamples(normalizedKayaString, t);

      return (
        <div className="flex flex-col gap-2">
          <p className="text-base font-normal">
            {formatMetricsCubeString(normalizedKayaString)}
          </p>
          {t(`explorerDimensions.${normalizedKayaString}.description`)}

          {examples.length > 0 && (
            <div className="flex flex-col gap-1 text-sm mt-2">
              <p className="text-sm font-medium">
                {t('explorerDimensions.examples')}
              </p>
              <ul className="list-disc pl-4">
                {examples.map((example) => (
                  <li className="break-words" key={`example-${example}`}>
                    {example}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      );
    },
    [t, formatMetricsCubeString],
  );

  return {
    getMetricValue,
    formatMetricValue,
    metricSuffix,
    formatMetricsCubeString,
    getMetricOrDimensionDescription,
  };
}

// Return all examples for a given dimension key
const getExamples = (key: string, t: ReturnType<typeof useTranslations>) => {
  let i = 0;
  const examples = [];

  while (t.has(`explorerDimensions.${key}.examples.${i}`)) {
    examples.push(t(`explorerDimensions.${key}.examples.${i}`));
    i++;
  }

  return examples;
};
