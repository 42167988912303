export enum ReleaseMetric {
  // Enum values match the table names in (kaya) collect__metrics__X
  TOTAL_FOOTPRINT = 'total_footprint',
  PRODUCT_FOOTPRINT = 'product_footprint',
  UNITS = 'units',
  PRODUCT_COUNT = 'product_count',
  RELATIVE_UNCERTAINTY = 'relative_uncertainty',
  COLLECTION_RATE = 'collection_rate',
  COMPLETION_RATE = 'completion_rate',
  MEASURED_RATE = 'measured_rate',
  COVERAGE_RATE = 'coverage_rate',
  NAMED_MATERIALS_SHARE = 'named_materials_share',
  KNOWN_LOCATION = 'known_location',
  KNOWN_FACTORY = 'known_factory',
  KNOWN_LOSS_RATE = 'known_loss_rate',
  KNOWN_PRODUCT_WEIGHT = 'known_product_weight',
  KNOWN_COMPONENT_WEIGHT = 'known_component_weight',
  KNOWN_RAW_MATERIALS = 'known_raw_materials',
  KNOWN_TEXTILE_FORMATION_DTEX = 'known_textile_formation_dtex',
  KNOWN_TEXTILE_FORMATION_GSM = 'known_textile_formation_gsm',
  KNOWN_TEXTILE_FORMATION_METHOD = 'known_textile_formation_method',
}

export interface ReleaseTask {
  title: string;
  asanaUrl: string;
  completed: boolean;
  owner: string;
  isOwnerExternal: boolean;
}
