import classNames from 'classnames';

export type DropdownOptionGroupPrimitiveProps = {
  label: string;
  children: React.ReactNode;
  index: number;
};

export const DropdownOptionGroupPrimitive = ({
  label,
  children,
  index,
}: DropdownOptionGroupPrimitiveProps) => {
  // Don't show empty groups, for instance when using autocomplete and no options matched
  if (
    // If no children, or children is null
    !children ||
    // Or children is an array and it's empty (length is 0)
    (Array.isArray(children) && children.length === 0)
  ) {
    return null;
  }

  return (
    <>
      <div
        className={classNames(
          'text-xs text-carbon-500 font-medium px-2 mt-1 bg-white sticky top-0',
          index > 0 && 'border-t-carbon-100 border-t-2 pt-2',
        )}
      >
        {label}
      </div>
      {children}
    </>
  );
};
