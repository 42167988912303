export type RawCellValue =
  | string
  | number
  | boolean
  | null
  | undefined
  | string[]
  | number[];

export type CsvCellValue = string;

export default function convertCellToCsvValue(
  cell: RawCellValue,
): CsvCellValue {
  let printedCsvValue = '';
  let isNumber = false;

  if (typeof cell === 'undefined') {
    printedCsvValue = '';
  }
  if (cell === null) {
    printedCsvValue = 'null';
  }
  if (typeof cell === 'string') {
    printedCsvValue = cell;
  }
  if (typeof cell === 'boolean') {
    printedCsvValue = cell ? 'TRUE' : 'FALSE';
  }
  if (Array.isArray(cell)) {
    printedCsvValue = cell.join(', ');
  }
  if (typeof cell === 'number') {
    // Limit number precision so that excel imports it as a number
    printedCsvValue = Number(cell.toFixed(14)).toString();
    isNumber = true;
  }

  // Always trim whitespace
  printedCsvValue = printedCsvValue.trim();

  // Make sure we escape content that could be interpreted as Excel formulas
  if (
    printedCsvValue.startsWith('=') ||
    printedCsvValue.startsWith('+') ||
    printedCsvValue.startsWith('-') ||
    printedCsvValue.startsWith('@')
  ) {
    printedCsvValue = `'${printedCsvValue}`;
  }

  // Always wrap in double quotes, except for numbers
  if (!isNumber) {
    printedCsvValue = `"${printedCsvValue.replace(/"/g, '')}"`;
  }

  return printedCsvValue;
}
