import Skeleton from '@carbonfact/ui-components/src/Skeleton';
import { Combobox, ComboboxButton, ComboboxOptions } from '@headlessui/react';
import { Button } from 'app/components/Button';
import Checkbox from 'app/components/Checkbox';
import classNames from 'classnames';
import { useTranslations } from 'next-intl';
import { type ReactNode, useMemo, useState } from 'react';

import { compact, uniq } from 'lodash';
import {
  DropdownOptionPrimitive,
  type DropdownOptionPrimitiveProps,
} from '../../primitives';
import { AutocompleteInputPrimitive } from '../../primitives/AutoCompleteInputPrimitive';
import { DropdownButtonPrimitive } from '../../primitives/DropdownButtonPrimitive';
import { DropdownOptionGroupPrimitive } from '../../primitives/DropdownOptionGroupPrimitive';
import { DropdownOptionsScrollerPrimitive } from '../../primitives/DropdownOptionsScrollerPrimitive';
import { PlaceholderPrimitive } from '../../primitives/PlaceholderPrimitive';

type OptionType = DropdownOptionPrimitiveProps<string>['option'];
const allKey = '$all$';
export type DropdownMultiSelectProps = {
  label?: string;
  options: OptionType[];
  value?: string[];
  disabled?: boolean;
  backgroundColor?: string;
  placeholder?: string;
  onChange: (val: string[]) => void;
  className?: string;
  height?: string;
  showArrow?: boolean;
  loading?: boolean;
  actions?: ReactNode;
  groups?: {
    label: string;
    optionKeys: OptionType['value'][];
  }[];
  align?: 'left' | 'right';
  includeSelectAll?: boolean;
  autocomplete?: boolean;
};
export const MultiSelect = ({
  label,
  options,
  disabled,
  backgroundColor,
  placeholder,
  className = '',
  value = [],
  height = 'h-8',
  loading,
  includeSelectAll,
  align,
  actions,
  groups,
  onChange,
  showArrow = true,
  autocomplete,
}: DropdownMultiSelectProps) => {
  const [autocompleteSearchTerm, setAutocompleteSearchTerm] = useState('');
  const t = useTranslations();
  const [showOptions, setShowOptions] = useState(false);
  const [selectedOptions, setSelectedOptions] =
    useState<OptionType['value'][]>(value);
  let bgColor = 'bg-white';
  if (!placeholder) {
    t('Dropdown.selectOption');
  }
  if (backgroundColor) {
    bgColor = backgroundColor;
  }

  const filteredOptions = autocomplete
    ? options.filter((option) =>
        option.label
          .toLowerCase()
          .includes(autocompleteSearchTerm.toLowerCase()),
      )
    : options;

  const handleOnChange = (selection: OptionType[]) => {
    if (selection.find((o) => o.value === allKey)) {
      if (selection.length === options.length + 1) {
        setSelectedOptions([]);
      } else {
        setSelectedOptions(options.map((o) => o.value));
      }
    } else {
      setSelectedOptions(selection.map((o) => o.value));
    }
  };

  const optionsMap = useMemo(() => {
    const map = new Map<string, OptionType>();
    for (const option of options) {
      map.set(option.value, option);
    }
    return map;
  }, [options]);

  const groupedOptionsMap = useMemo(() => {
    // Build a map to know which option is grouped
    const map = new Map<string, true>();
    for (const group of groups || []) {
      for (const option of group.optionKeys) {
        map.set(option, true);
      }
    }
    return map;
  }, [groups]);

  const handleSave = () => {
    setShowOptions(false);
    setAutocompleteSearchTerm('');
    onChange(selectedOptions.filter((o) => o !== allKey));
  };
  const handleCancel = () => {
    setShowOptions(false);
    setAutocompleteSearchTerm('');
  };
  return (
    <Combobox
      disabled={disabled}
      multiple
      onChange={handleOnChange}
      value={selectedOptions.map((sel) => options.find((o) => o.value === sel))}
    >
      {() => (
        <>
          <div
            className={classNames(
              'relative border-carbon-100 bg-carbon-50',
              height,
              className,
            )}
          >
            {loading && <Skeleton height={height} />}
            {!loading && (
              <ComboboxButton
                className={classNames(
                  'rounded-md shadow-carbon border-[1px] box-border border-carbon-100 items-center px-1 focus:outline-none h-full w-full justify-between flex flex-row',
                  bgColor,
                )}
                onClick={() => {
                  setShowOptions(!showOptions);
                  setAutocompleteSearchTerm('');
                }}
              >
                <DropdownButtonPrimitive
                  showArrow={showArrow}
                  disabled={disabled}
                  prefix={label}
                >
                  <div className="font-medium text-sm truncate  whitespace-nowrap">
                    {options
                      .filter((v) => value.includes(v.value))
                      .map((v) => v.label)
                      .join(', ') || (
                      <PlaceholderPrimitive text={placeholder} />
                    )}
                  </div>
                </DropdownButtonPrimitive>
              </ComboboxButton>
            )}

            {showOptions && (
              <div>
                <ComboboxOptions
                  static
                  className={classNames(
                    'absolute p-2 z-50 w-72 origin-top-right max-h-[50vh] overflow-y-scroll rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none',
                    align === 'left' ? 'left-0' : 'right-0',
                  )}
                >
                  {autocomplete && (
                    <AutocompleteInputPrimitive
                      label={label}
                      placeholder="Search options"
                      onChange={setAutocompleteSearchTerm}
                    />
                  )}
                  {filteredOptions.length === 0 && (
                    <div className="p-1 text-sm text-carbon-500">
                      {t('Dropdown.noResultFound')}
                    </div>
                  )}
                  <DropdownOptionsScrollerPrimitive>
                    {actions}

                    {includeSelectAll && (
                      <DropdownOptionPrimitive
                        key={allKey}
                        option={{ value: allKey, label: 'Select All' }}
                        selected={selectedOptions.length === options.length}
                      >
                        <div className="flex items-center gap-2">
                          <Checkbox
                            checked={
                              selectedOptions.length === options.length || false
                            }
                          />
                          {t('Dropdown.Multiselect.selectAll')}
                        </div>
                      </DropdownOptionPrimitive>
                    )}
                    {groups?.map((group, index) => {
                      const groupOptions = compact(
                        // Handling duplicate keys & missing keys
                        uniq(group.optionKeys).map((key) =>
                          optionsMap.get(key),
                        ),
                      ).filter((option) =>
                        filteredOptions.some(
                          (fo) => fo.value === option?.value,
                        ),
                      );

                      if (groupOptions.length === 0) {
                        return null;
                      }
                      return (
                        <DropdownOptionGroupPrimitive
                          key={group.label}
                          index={index}
                          label={group.label}
                        >
                          {groupOptions.map((option) => (
                            <DropdownOptionPrimitive
                              key={`${group.label}-${option.value}`}
                              option={option}
                              selected={selectedOptions.includes(option.value)}
                            >
                              <div className="flex items-center gap-2">
                                <Checkbox
                                  checked={
                                    selectedOptions.includes(option.value) ??
                                    false
                                  }
                                />
                                {option.label}
                              </div>
                            </DropdownOptionPrimitive>
                          ))}
                        </DropdownOptionGroupPrimitive>
                      );
                    })}

                    {
                      // Show group-less options
                      filteredOptions
                        .filter(
                          (option) => !groupedOptionsMap.has(option.value),
                        )
                        .map((option) => (
                          <DropdownOptionPrimitive
                            key={option.value}
                            option={option}
                            selected={selectedOptions.includes(option.value)}
                          >
                            <div className="flex items-center gap-2">
                              <Checkbox
                                checked={
                                  selectedOptions.includes(option.value) ??
                                  false
                                }
                              />
                              {option.label}
                            </div>
                          </DropdownOptionPrimitive>
                        ))
                    }
                  </DropdownOptionsScrollerPrimitive>
                  <div className="flex flex-row gap-2 justify-end">
                    <Button.Default variant="secondary" onClick={handleCancel}>
                      {t('Dropdown.Multiselect.cancel')}
                    </Button.Default>
                    <Button.Default variant="primary" onClick={handleSave}>
                      {t('Dropdown.Multiselect.confirm')}
                    </Button.Default>
                  </div>
                </ComboboxOptions>
              </div>
            )}
          </div>
        </>
      )}
    </Combobox>
  );
};
