import Icon from '@carbonfact/ui-components/src/Icon';
import { ComboboxOption } from '@headlessui/react';
import classNames from 'classnames';

type DropdownOption<T = string> = {
  value: T;
  label: string;
  description?: string;
  hoverContent?: React.ReactNode;
  disabled?: boolean;
};
export type DropdownOptionPrimitiveProps<T = string> = {
  option: DropdownOption<T>;
  onHover?: (option: DropdownOption<T> | null) => void;
  selected?: boolean;
  children: React.ReactNode;
};

export const DropdownOptionPrimitive = <T = string>({
  option,
  selected,
  children,
  onHover,
}: DropdownOptionPrimitiveProps<T>) => {
  return (
    <ComboboxOption
      onMouseLeave={() => {
        onHover?.(null);
      }}
      onMouseEnter={() => {
        if (option.disabled || !option.hoverContent) return;
        onHover?.(option);
      }}
      disabled={option.disabled}
      className={classNames(
        'rounded-md select-none p-2 text-sm',
        option.disabled
          ? 'text-gray-400'
          : 'cursor-pointer text-gray-900 hover:text-carbon-800 hover:bg-carbon-50',
      )}
      value={option}
    >
      <div className="flex flex-col">
        <div className="flex justify-between text-sm">
          <div
            className={classNames(
              selected ? 'font-medium' : 'font-normal',
              'w-full',
            )}
          >
            <div className="flex flex-row whitespace-nowrap justify-between items-center w-full gap-2">
              {children}
              {selected && (
                <Icon
                  width={15}
                  height={15}
                  icon={{ source: 'local', name: 'check', type: 'line' }}
                />
              )}
            </div>
          </div>
        </div>
        {option.description && (
          <p className="text-xs text-carbon-500 font-normal">
            {option.description}
          </p>
        )}
      </div>
    </ComboboxOption>
  );
};
